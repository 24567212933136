import Input from '@/components/shared/AppInput';
import { phoneRegExp } from '@/utils';
import { useFormik } from 'formik';
import React, { MouseEventHandler, useCallback, useRef, useState } from 'react';
import * as Yup from 'yup';
import BlockImage from '@/assets/images/block-image.png';
import UnBlockImage from '@/assets/images/unblock-image.png';
import { useInsert_Contact_FormMutation } from '@/generated/graphql';
import { useBlockContext } from '@/context/useBlockContext';

export interface IInfomationModalProps {
  setShowModel: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentCategory?: React.Dispatch<React.SetStateAction<string | null>>;
  title: string;
  idCategory?: string;
  noClickOutSide?: boolean;
}

const InfomationModal = (props: IInfomationModalProps) => {
  const {
    setShowModel,
    title,
    setCurrentCategory,
    idCategory,
    noClickOutSide,
  } = props;

  const { setBlock } = useBlockContext() as IBlockContext;

  const [step, setStep] = useState<'1' | '2'>('1');

  const modalRef = useRef<HTMLDivElement>(null);

  const [insertContactFormMutation] = useInsert_Contact_FormMutation({
    onCompleted: () => {
      setStep('2');
      setBlock({ is: false });
      setCurrentCategory && setCurrentCategory(idCategory || '');
    },
  });

  const handleClickOutside: MouseEventHandler<HTMLDivElement> = useCallback(
    event => {
      if (noClickOutSide && step === '1') return;
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        setShowModel(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setShowModel],
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      email: '',
      phone: '',
    },
    onSubmit: async values => {
      const { email, name, phone } = values;

      if (step === '1') {
        await insertContactFormMutation({
          variables: {
            objects: {
              email,
              full_name: name,
              phone_number: phone,
              content: `${title}`,
              type: 'Mở khóa',
            },
          },
        });
      } else {
        setShowModel(false);
      }
    },
    validationSchema:
      step === '1'
        ? Yup.object({
            email: Yup.string()
              .email('Email chưa đúng định dạng')
              .required('Vui lòng nhập Email của bạn'),
            phone: Yup.string()
              .required('Vui lòng nhập số điện thoại của bạn')
              .matches(phoneRegExp, 'Số điện thoại chưa đúng định dạng'),
          })
        : null,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div
        onClick={handleClickOutside}
        className="fixed inset-0 z-50 flex justify-center bg-[#00000080]"
      >
        <div
          ref={modalRef}
          className={`mx-2 ${
            step === '1' ? 'mt-[100px]' : 'mt-[180px]'
          } flex h-fit w-full flex-col gap-4 rounded-xl bg-white p-8 md:inset-x-auto md:top-[250px] md:mx-0 lg:w-[45%]`}
        >
          <div className="flex flex-col gap-4">
            {/* eslint-disable-next-line @next/next/no-img-element, jsx-a11y/alt-text */}
            <img
              src={step === '1' ? BlockImage.src : UnBlockImage.src}
              width="56px"
              height="56px"
            />
            {step === '1' ? (
              <p className="text-2xl font-semibold">
                Bạn cần xác nhận thông tin người dùng để có thể xem tiếp các nội
                dung khác
              </p>
            ) : (
              <p
                className="text-2xl font-semibold"
                style={{ fontFamily: 'Montserrat, sans-serif' }}
              >
                Bạn đã có thể xem tiếp
              </p>
            )}

            <p
              className="text-medium font-normal leading-[34.8px]"
              style={{ fontFamily: 'Montserrat, sans-serif' }}
            >
              {step === '1'
                ? 'Vui lòng nhập thông tin của bạn để mở khóa các nội dung tuyệt vời khác trên Tapchitrading'
                : 'Hãy tận hưởng những nội dung tuyệt vời trên Tapchitrading nhé'}
            </p>
          </div>
          {step === '1' && (
            <div className="grid grid-cols-1 gap-3 md:grid-cols-1">
              <div className="col-span-1">
                <Input
                  type="text"
                  name="name"
                  label="Họ và tên của bạn"
                  className="text-body3 border-gray-variants-5 w-full rounded-xl border-[1px] p-4 outline-none"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                />
                {/* {formik?.errors?.name && (
                  <p className="text-xs italic text-red-600">
                    {formik.errors.name}
                  </p>
                )} */}
              </div>
              <div className="relative col-span-1">
                <Input
                  type="text"
                  required
                  name="email"
                  label="Email của bạn"
                  className="text-body3 border-gray-variants-5 w-full rounded-xl border-[1px] p-4 outline-none"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                />
                {formik?.errors?.email && (
                  <p className="text-xs italic text-red-600">
                    {formik.errors.email}
                  </p>
                )}
              </div>
              <div className="relative col-span-1">
                <Input
                  type="text"
                  required
                  name="phone"
                  label="Số điện thoại"
                  className="text-body3 border-gray-variants-5 w-full rounded-xl border-[1px] p-4 outline-none"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                />
                {formik?.errors?.phone && (
                  <p className="text-xs italic text-red-600">
                    {formik.errors.phone}
                  </p>
                )}
              </div>
            </div>
          )}

          <button
            type="submit"
            className="mt-3 rounded bg-[#004958] py-4 text-lg font-semibold text-white"
          >
            {step === '1' ? 'Gửi thông tin' : 'Xem tiếp'}
          </button>
        </div>
      </div>
    </form>
  );
};
export default InfomationModal;
