import React, { InputHTMLAttributes, useId } from 'react';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
}

const Input: React.FC<InputProps> = props => {
  const { required, label, value } = props;
  const id = useId();
  return (
    <div className="relative w-full">
      <input
        id={id}
        className="text-body3 bg-neutral-background-gray placeholder:text-gray-variants-6 text-gray-variants-9 peer h-[52px] w-full rounded-[6px] border-2 border-transparent px-[17px] py-[10px] outline-none focus:border-blue-500 focus:outline-none"
        {...props}
      />
      {label && (
        <label
          className={`pointer-events-none absolute bottom-1/2 left-[17px] translate-y-1/2 text-gray-600 peer-focus:hidden ${
            value && 'hidden'
          }`}
          htmlFor={id}
        >
          {label} {required && <span className="text-red-500">*</span>}
        </label>
      )}
    </div>
  );
};

export default Input;
